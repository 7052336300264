import React from 'react';
import { Accordion, Paper, Text, Alert } from '@mantine/core';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/components/prism-bash.min';
import 'prismjs/components/prism-javascript.min';

const Instructions = () => {
  return (
    <Paper padding="md" shadow="xs" style={{"marginBottom": "45px"}}>
      <Text align="center" size="xl" weight={700}>
        Instructions
      </Text>

      <Accordion>
        <Accordion.Item label="Step 1: Register your application" style={{"marginTop": "40px"}}>
        <Text style={{ fontWeight: 'bold' }}>
            Introduction
          </Text>
        <Text style={{"marginTop": "15px"}}>
          To use VX with test cases, ensure that "testing mode" is active for the user who generated the VX session token (all RAI users that are not "production" will have this active by default). 
          The system knows what test case you want to use by Last Name, so when creating a test case use a unique last name and then the other data you enter will be what is matched against. 
          When testing a system that includes velocity checks like the RAI VX process, it's important to use unique cases. This process is designed to detect repetition and block verified users 
          to prevent fraudulent activity. Using unique test cases helps avoid triggering these preventive measures.
        </Text>
         
          
          <Text style={{ fontWeight: 'bold', "marginTop": "20px" }}>
            The Current RAI Veratad VX Journey
          </Text>
          <Text style={{"marginTop": "15px"}}>
            There are three verification processes in the current journey. These processes include: 
          </Text>
          <pre className="language-jsx">
            <code>
              {`1. Phone Verification\n2. Social Security Number Verification\n3. Identity Document Verification`}
            </code>
          </pre>
          <Text style={{ fontWeight: 'bold', "marginTop": "20px" }}>
            Phone Verification Process
          </Text>
          <Text style={{"marginTop": "15px"}}>
            The phone verification rules include: 
          </Text>
          <pre className="language-jsx">
            <code>
              {`1. The phone and year of birth match the identity\n2. The identity is of age\n3. The OTP sent is entered correctly\n4. The phone number is valid and not VOIP`}
            </code>
          </pre>
          <Text style={{"marginTop": "15px"}}>
            Therefore, to pass at this step in the process, create a test case with a number you have access to
            and then enter the data into the VX session the same way you have set up the case. 
          </Text>
          
          
          <Alert title="Testing Tip" color="green" style={{"marginTop": "15px"}}>
                You can enter a real phone number associated with a fake data case. This will give you the ability to pass the phone verification step by receiving the OTP to the number and the test data will be associated with the phone. 
        </Alert>

        <Text style={{ fontWeight: 'bold', "marginTop": "20px" }}>
            SSN Verification Process
          </Text>
          <Text style={{"marginTop": "15px"}}>
            The SSN verification rules include: 
          </Text>
          <pre className="language-jsx">
            <code>
              {`1. The last 4 SSN and year of birth match the identity\n2. The identity is of age`}
            </code>
          </pre>
          <Text style={{"marginTop": "15px"}}>
            Therefore, to pass at this step in the process, create a test case and enter the data exactly as
            you have created the case.
          </Text>

          <Text style={{ fontWeight: 'bold', "marginTop": "20px" }}>
            ID Document Verification Process
          </Text>
          <Text style={{"marginTop": "15px"}}>
          You can NOT create a test case to be used with the document verification process. You must use the information below:
          </Text>

          <pre className="language-jsx">
            <code>
              {`First Name: Louisa\nLast Name: dcamspluspass (this is case sensitive)\nDOB: 06/29/1972`}
            </code>
          </pre>
          
          <Alert title="Testing Tip" color="green" style={{"marginTop": "15px"}}>
                You can enter any image. You may be blocked by the validation tool on the first try if the image does not look like an ID, but just upload one more time and the system will accept the image. 
        </Alert>

        </Accordion.Item>

        
      </Accordion>
    </Paper>
  );
};

export default Instructions;
