import React, { useState } from 'react';
import logo from './logo.svg';
import Form from './components/Form.js';
import Instructions from './components/Instructions.js';
import './App.css';
import { Container, Col, Grid, Button } from '@mantine/core';

function App() {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <Container padding="md" style={{ paddingTop: '2rem' }}>
        <Grid justify="space-between">
          <Col span={6}>
            <h1>RAI Veratad VX Testing</h1>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button onClick={toggleForm}>
              {showForm ? 'Instructions' : 'Add Test Case'}
            </Button>
          </Col>
        </Grid>
        {showForm ? (
          <Grid>
            <Col style={{ marginTop: '1rem' }}>
              <Form />
            </Col>
          </Grid>
        ) : (
          <Grid>
            <Col>
              <Instructions />
            </Col>
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default App;
