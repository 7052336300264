import React, { useState } from "react";
import "./Form.css";
import {
  Container,
  Col,
  TextInput,
  Button,
  Paper,
  Text,
  Grid,
  Select,
  useMantineTheme,
  LoadingOverlay,
  CopyButton,
  Group,
  Tooltip,
  ActionIcon,
  rem,
  Alert,
  Box,
} from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheck, IconCopy, IconInfoCircle } from "@tabler/icons-react";

const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

function Form() {
  const theme = useMantineTheme();

  const generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const [form, setForm] = useState({
    fn: "",
    ln: generateRandomString(15),
    addr: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    ssn: "",
    dob: null,
  });

  const [errors, setErrors] = useState({});

  const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
  const ssnRegex = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleDateChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, dob: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!phoneRegex.test(form.phone)) {
      newErrors.phone = "Invalid phone number";
    }

    if (!ssnRegex.test(form.ssn)) {
      newErrors.ssn = "Invalid SSN";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const convertDateFormat = (date) =>
      `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}${String(date.getDate()).padStart(2, "0")}`;

    const formattedDate = convertDateFormat(form.dob);

    try {
      const response = await fetch(
        "https://be.demo.veratad.app/testing/insert-vdp.php",
        {
          method: "POST",

          body: JSON.stringify({ ...form, dob: formattedDate }),
        }
      );

      const data = await response.json();
      console.log("Result:", data.result);

      if (data.result === "success") {
        setStatus("success");
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setForm({
      fn: "",
      ln: generateRandomString(15),
      addr: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      ssn: "",
      dob: null,
    });
    setStatus(null);
  };

  return (
    <Container>
      <Paper padding="md" shadow="xs">
        <Text
          align="center"
          size="xl"
          weight={700}
          style={{ marginBottom: "40px" }}
        >
          Add a Test Case
        </Text>
        <LoadingOverlay visible={loading} />
        {status === "success" && (
          <div className="status-message success">
            <Text align="center" size="xl" color={theme.colors.success}>
              Submission successful!
            </Text>
            <Button fullWidth onClick={resetForm}>
              Add another
            </Button>
          </div>
        )}
        {status === "error" && (
          <div className="status-message error">
            <Text align="center" size="xl" color={theme.colors.red}>
              Submission failed. Please try again.
            </Text>
            <Button fullWidth onClick={resetForm}>
              Try again
            </Button>
          </div>
        )}
        {status === null && (
          <form onSubmit={handleSubmit}>
            <Grid gutter="md">
              <Col span={6}>
                <TextInput
                  label="First Name"
                  name="fn"
                  value={form.fn}
                  onChange={handleChange}
                  required
                />

                <TextInput
                  label={
                    <>
                      <Group spacing={5}>
                        <Text>Last Name</Text>
                        <CopyButton value={form.ln} timeout={2000}>
                          {({ copied, copy }) => (
                            <Tooltip
                              label={
                                copied ? "Copied Last Name" : "Copy Last Name"
                              }
                              withArrow
                              position="right"
                            >
                              <ActionIcon
                                color={copied ? "teal" : "gray"}
                                variant="subtle"
                                onClick={copy}
                              >
                                {copied ? (
                                  <IconCheck style={{ width: rem(16) }} />
                                ) : (
                                  <IconCopy style={{ width: rem(16) }} />
                                )}
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </CopyButton>
                      </Group>
                    </>
                  }
                  disabled
                  name="ln"
                  value={form.ln}
                  onChange={handleChange}
                />

                <Alert
                  m="xs"
                  size="xs"
                  variant="light"
                  color="red"
                  title="Important"
                  icon={<IconInfoCircle />}
                >
                  <Text size="xs">
                    Last Name is the test_key and will be used to look up the
                    record during the verification process. Make sure when using
                    this test case you enter it exactly as copied here.
                  </Text>
                </Alert>

                <TextInput
                  label="Street Address"
                  name="addr"
                  value={form.addr}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="City"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                  required
                />
                <Select
                  label="State"
                  name="state"
                  value={form.state}
                  onChange={(value) =>
                    setForm((prevForm) => ({ ...prevForm, state: value }))
                  }
                  data={US_STATES.map((state) => ({
                    label: state,
                    value: state,
                  }))}
                  required
                />
                <TextInput
                  label="Zip"
                  name="zip"
                  value={form.zip}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col span={6}>
                <TextInput
                  label="Phone"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  required
                  error={errors.phone}
                />
                <TextInput
                  label="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Social Security"
                  name="ssn"
                  value={form.ssn}
                  onChange={handleChange}
                  required
                  error={errors.ssn}
                  maxLength={11}
                  placeholder="XXX-XX-XXXX"
                />
                <div className="date-picker-wrapper">
                  <label htmlFor="dob">Date of Birth</label>
                  <DatePicker
                    id="dob"
                    selected={form.dob}
                    onChange={handleDateChange}
                    required
                    dateFormat="yyyy-MM-dd"
                    className="date-picker-input"
                  />
                </div>
              </Col>
            </Grid>
            <Button type="submit" fullWidth style={{ marginTop: "40px" }}>
              Save
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
}

export default Form;
